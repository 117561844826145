import styled from 'styled-components';
import { ImageUrl } from '../../../utils/constants';
const backgroundUrl = `url(${ImageUrl}/images/NewDashboardV2/workoutCount.png)`;


const MyMonthlyActivityContainer = styled.div`
width: 100%;
max-width: 425px;
margin-bottom: 15px;
float: left;
margin-right: 25px;
height: 100%;
> div: first-child {
  text-transform: capitalize;
    color: #0D4270;
    font-size: 18px;
    line-height: 24px;
    font-family: 'Rubik-Medium';
    margin-bottom: 15px;
}
> div: last-child {
  height: 100%;
  width: 100%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
}
@media (max-width: 1250px) {
  float: left;
  width: calc((100% - 30px) / 2);
  margin-right: 0px;
}
@media (max-width: 688px) {
  margin-top: 15px;
  float: left;
  width: 100%;
	margin-right: 0px;
	margin-bottom: 0px;
}
}
.mainText {
  font-family: Rubik-Medium;
    font-size: 16px;
}
.timeZone {
   color: FFFFFF;
    margin-top: 13px;
    top: -2px;
    border-radius: 16px;
    margin-bottom: 15px;
    background: #FFFFFF;
    width: 34%;
    justify-content: center;
    text-align: center;
    margin-left: 143px;
    margin-right: -63px;
    #left_img img {REPLACE    }
    #right_img  img  {
      width: 8px;
      float: right;
      margin-right: -77px;
      margin-top: -18px;
    } 
}
}
`;
const WorkoutContainer = styled.div`
height: 136px;
border-radius: 6px;
margin-bottom: 15px;
margin-top: -13px;
background-image: ${backgroundUrl};
background-size: 100% 100%;
width: 100%;
background-repeat: no-repeat;
display: flex;
justify-content: center;
flex-flow: column;
align-items: center;
cursor: pointer;
> div: first-child {
  font-family: 'Rubik-Medium';
  color: #FFF;
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 10px;
}
> div: last-child {
  font-family: 'Rubik';
  color: #FFF;
  font-size: 14px;
  line-height: 11px;
}
width: 100%;
`;
const CountContainer = styled.div`
height: 100%;
width: 100%;
`;
const Card = styled.div`
background: #FFFFFF;
box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.08);
border-radius: 6px;
width: calc((100% - 10px) /2);
padding: 5px;
align-items: center;
flex-flow: row;
float: left;
margin-bottom: 10px;
max-height: 78px;
height: 100%;
display: grid;
grid-template-columns: 40% 60%;
grid-template-rows: repeat(1, 68px);
grid-auto-flow: row;
cursor: pointer;
> div: first-child {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
  border-radius: 6px;
  margin-right: 8px;
  padding: 5px; 
  > img {
    width: 40px;
   height: 40px;
  left: 474px;
   top: 580px;REPLACE  }
}
> div: last-child {
  > div: first-child {
    color: #D8D8D8;
    color: #9c9c9c;
    font-family: Rubik;
    font-size: ${ ({isFrench}) => isFrench ? "14px" : "16px"};
    line-height: 11px;
    width: 100%;
    margin-bottom: 15px;
    word-break: break-all;
  }
  > div: last-child {
    color: #0D4270;
    font-size: 24px;
    line-height: 11px;
    font-family: 'Rubik-Medium';
    width: 100%;
  }
}
&:nth-child(odd) {
  margin-right: 10px;
}
`;
const YearUI = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
  .corner{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    cursor: pointer;
    >i{
      color: #fff;
    }
  }
  .yearContainer{
    width: 80px;
    padding: 5px;
    background: linear-gradient(90deg, rgba(0, 177, 188, 0.15)
    -1.11%, rgba(117, 217, 163, 0.15)
    97.46%);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik-Medium',
    font-size: 18px;
    color: #fff;
    > div {
      color: #0D4270;
    }
  }
`;
export {
  MyMonthlyActivityContainer, WorkoutContainer, CountContainer, Card, YearUI
}