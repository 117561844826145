import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MyMonthlyActivityContainer, WorkoutContainer, CountContainer, Card, YearUI} from './styles';
import { getWorkoutActivityCount } from '../../../redux/actions';
import { findMonth } from '../../../utils/methods';
import { ImageUrl } from '../../../utils/constants';
import { withTranslation } from 'react-i18next';

class MyMonthlyActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: new Date().getFullYear()
    }
  }

  componentDidMount() {
    const { getWorkoutActivityCount } = this.props;
    getWorkoutActivityCount(this.state.selectedYear);
  }

  getActivityWiseData = (activityType) => {
    const { hideWorkoutactivity } = this.props;
    hideWorkoutactivity(activityType, this.state.selectedYear);
  }

  dayWorkoutHistory = () => {
    const { displayWorkoutActivity } = this.props;
    displayWorkoutActivity();
  }

  modifyDate = (type) => {
    const { selectedYear } = this.state;
    this.setState({selectedYear: type ? selectedYear + 1 : selectedYear - 1}, ()=> {
      this.props.getWorkoutActivityCount(this.state.selectedYear);
    })
  }

  render() {
    const { workoutActivityCount, t } = this.props;
    const today = new Date();
    const month = findMonth(today.getMonth());
    const lang = localStorage.getItem("lang");
    return(
      <MyMonthlyActivityContainer>
        <div>
          <WorkoutContainer onClick={this.dayWorkoutHistory}>
            <div>
              {month} {t("Workouts")}
            </div>
            {workoutActivityCount && workoutActivityCount.count ?
              <div>{workoutActivityCount.count}{' '} {t("Total workouts completed")}</div>
              : null}
          </WorkoutContainer>
          <div className ="mainText" >
            {t("My Annual Workout Activity")}
          </div>
          <YearUI>
            <div className="corner" style={{"margin-left":"15px"}} onClick={() => this.modifyDate(0)}><img src="/public/images/NewDashboardV2/arrowLeftt.png" alt="icon2" width="30" height="30"/></div>
            <div className="yearContainer"><div>{this.state.selectedYear} </div></div>
            <div className="corner" style={{"margin-right":"15px"}} onClick={() => (new Date().getFullYear() !== this.state.selectedYear) && this.modifyDate(1)}><img src="/public/images/NewDashboardV2/arrowRightt.png" alt="icon2" width="30" height="30"/></div>
          </YearUI>
          <CountContainer>
            {workoutActivityCount && workoutActivityCount.workout_details.length > 0 ?
              workoutActivityCount.workout_details.map((data, index) => (
                <Card isFrench={lang === "fr"} key={index} onClick={() => this.getActivityWiseData(data.activity)}>
                  <div>
                    <img src={`${ImageUrl}/${data.icon}`}/> 
                  </div>
                  <div>
                    <div>{t(data.activity)}</div>
                    <div>{data.activity_count}</div>
                  </div>
                </Card>
              ))
              : <div style={{textAlign:"center"}}>{t("No workouts available")}</div>
            }
          </CountContainer>
        </div>
      </MyMonthlyActivityContainer>
    );
  }
}
MyMonthlyActivity.propTypes = {
  getWorkoutActivityCount: PropTypes.func,
  workoutActivityCount: PropTypes.object,
  hideWorkoutactivity: PropTypes.func,
  displayWorkoutActivity: PropTypes.func,
  getDailyWorkoutHistory: PropTypes.func,
  t: PropTypes.func
};
const mapStateToProps = (state) => ({
  workoutActivityCount: state.social.workoutActivityCount, 
  dayWiseWorkoutHistory: state.social.dayWiseWorkoutHistory

});
const mapDispatchToProps = (dispatch) => ({
  getWorkoutActivityCount: (selectedYear) => dispatch(getWorkoutActivityCount(selectedYear))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyMonthlyActivity));