import styled from 'styled-components';

const WorkoutContainer = styled.div`
margin-bottom: 15px;
width: 100%;
max-width: 425px;
margin-bottom: 15px;
float: left;
height: 100%;
> div: first-child {
  text-transform: capitalize;
  color: #0D4270;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
@media(max-width: 1250px) {
	margin-top: 15px;
  float: left;
  width: calc((100% - 30px) / 2);
  margin-right: 0px;
}
@media(max-width: 688px) {
  float: left;
  margin-top: 15px;
  width: 100%;
  height: 100%;
}
`;
const SubContainer = styled.div`
width: 100%;
background: #FFFFFF;
border-radius: 6px;
height: 100%;
`;
const CardSection = styled.div`
width: 100%;
display: flex;
justify-content: space-around;
align-items: center;
border-bottom: 1px solid #ECECEC;
min-height: 100px;
padding: 15px 0px;

/* min-height: ${({ minhieght }) => minhieght ? "200px" : "110px"}; */


.iconCard {
  border-radius: 6px;
  background: ${({intensityCheck}) => (intensityCheck === 0) ? 'linear-gradient(180deg, #FD7175 0%, #FF5257 100%)' : 'linear-gradient(180deg, #F6B479 0%, #FFA24E 100%)'}
}
> div: first-child {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-left: 15px;
  margin-right: 5px;
  min-height: 60px;
  border-radius: 6px;
  margin: 0px 5px auto 15px;
  
  > img {
    width: 40px;
   height: 40px;
   left: 464px;
   top: 570px;

  }
}
> div: last-child {
  width: 80%;
  display: flex;
  flex-flow: column;
	justify-content: flex-start;
	margin-left: 5px;
  > div: nth-child(2) {
    color: #0D4270;
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
}
&: last-child {
  border-bottom: none;
}
`;
const ActivityDetails = styled.div`
width: 100%;
display: flex;
flex-flow: row;
justify-content: space-between;
`;
const Details = styled.div`
display: flex;
justify-content: center;
align-items: center;
.counterContainer {
  display: flex;
  align-items: center;
  color: #333;
  color: rgb(156, 156, 156);
  .counterImage {
    margin-right: 5px;
  }
  .counterTitle {
    color: rgb(156, 156, 156);
  }
}
> div: first-child {
  margin-right: ${({marginRight}) => marginRight ? '2px' : '10px'};
  > img {
    width: 17px;
  }
}
> div: last-child {
	color: #858585;
	font-size: 14px;
	line-height: 24px;
  font-family: 'Rubik';
  color: rgb(156, 156, 156);
}
`;
const DayWorkOut = styled.div`
width: 100%;
background-color: #FFF;
border-radius: 6px;
padding: 0px 10px;
display: flex;
justify-content: space-evenly;
flex-flow: column;
height: 100%;
@media(min-width: 1550px) {
  padding: 0px;
}
@media(max-width: 1450px) and (min-width: 1300px){
  padding: 0px 5px;
}
`;
const TimeContainer = styled.div`
color: #858585;
font-size: 12px;
display: flex;
justify-content: space-between;
/* line-height: 24px; */
`;

const ButtonContainer = styled.div`
display:flex;
width:100%;


margin:none;
padding:none;
justify-content: flex-start;
margin-top: ${({margin}) => margin ? '15px' : '5px'};
>button{
 
 width: 45%;
height: 30px;

border: 1px solid rgba(58, 58, 58, 0.5);
box-sizing: border-box;
border-radius: 3px;
font-size: 14px;
line-height: 20px;
font-family: rubik-medium; 
color: #9C9C9C;
background-color:white;

}
.edit{
background-color:red;
border:red;

}

`;
const Workout = styled.div`
display:flex;
width:100%;

justify-content: space-between;




`;




export {
  WorkoutContainer, SubContainer, CardSection, ActivityDetails, Details, DayWorkOut, TimeContainer,ButtonContainer,Workout
}