/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import PropTypes from 'prop-types';
import { CalendarContainer, DayWorkOut, Title, Card, ActivityDetails, Details, DisplayFlex, Newwork, NewButtons } from './styles';
import { getWorkoutHistory, getMonthlyWorkoutStatus } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import { isEmpty } from 'lodash';
import { findMonth, convertMilesToKm } from '../../utils/methods';
import CustomCalender from '../CustomCalender';
import Example from './Example';
import { deleteWork } from './../../redux/actions';
import { ButtonContainer } from './SingleActivity/styles';
import { withTranslation } from 'react-i18next';

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
class WeeklyCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      selected: 'month',
      dayWiseListStatus: {},
      Showpopup: false,
      selectedIndex: "",
      showModal: false,
      WorkoutId: ""
    };
  }

  getStartDate = (currentDate, value) => moment(currentDate).startOf(value).format('MM-DD-YYYY');
  getEndDate = (currentDate, value) => moment(currentDate).endOf(value).format('MM-DD-YYYY');

  componentDidMount() {
    const { getWorkoutHistory, getMonthlyWorkoutStatus } = this.props;
    const { currentDate } = this.state;
    const currentDay = moment(currentDate).format('MM-DD-YYYY');
    getWorkoutHistory(currentDay);
    getMonthlyWorkoutStatus(this.getStartDate(currentDate, 'month'), this.getEndDate(currentDate, 'month'));
  }

  onChange = (value) => {
    const { getWorkoutHistory, getMonthlyWorkoutStatus } = this.props;
    this.setState({
      currentDate: new Date(value)
    }, () => {
      getWorkoutHistory(moment(value).format('MM-DD-YYYY'));
      getMonthlyWorkoutStatus(this.getStartDate(value, 'month'), this.getEndDate(value, 'month'));
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.monthlyActivityDateList !== this.props.monthlyActivityDateList) {
      this.setState({ dayWiseListStatus: this.props.monthlyActivityDateList })
    }
  }

  imageClick = (index) => {
    this.setState({ Showpopup: !this.state.Showpopup, selectedIndex: index });
  }


  callApi = (id) => {
    this.setState({ showModal: true, WorkoutId: id });
  }

  closeModal = () => {
    this.setState({ showModal: false });
  }

  render() {
    const { currentDate, dayWiseListStatus } = this.state;
    const { workoutHistory, deleteWork, t, user } = this.props;
    const today = new Date();
    const day = currentDate.getDay();
    const month = findMonth(currentDate.getMonth());
    const { WorkoutId } = this.state;
    const DistanceUnit = localStorage.getItem('DISTANCE');
    const registrationDateString = user && user.created_at ? moment(user.created_at).format("MM/DD/YYYY") : "NA";
    const registrationDate = registrationDateString !== "NA" ? moment(registrationDateString, "MM/DD/YYYY").toDate() : new Date();
    const formattedDate = moment(registrationDate).format('YYYY-MM-DD');
    return (
      <DisplayFlex bgColor={workoutHistory && !isEmpty(workoutHistory) ? 1 : 0}>
        <CalendarContainer bgColor={workoutHistory && !isEmpty(workoutHistory) ? 1 : 0}  >
          <CustomCalender
            onChange={(value) => this.onChange(value)}
            workoutDate={currentDate}
            daysWorkoutList={dayWiseListStatus}
            maxDate={today}
            minDate={formattedDate} />
        </CalendarContainer>
        {workoutHistory && !isEmpty(workoutHistory) ?
          <DayWorkOut padding={1}>
            <Title>
              {Days[day]}, {month} {moment(currentDate).format('Do')}
            </Title>
            {workoutHistory && workoutHistory.length > 0 ?
              workoutHistory.map((data, index) => (
                <Card intensityCheck={data.intesity ? 1 : 0} key={index}>
                  <div className={'iconCard'}>
                    <img src={`${ImageUrl}/${data.interest_icon}`} />
                  </div>
                  <div   >
                    <Newwork>
                      <div>{this.props.t(data.activity)}  </div>
                      <div>{data.intesity ? <img onClick={() => this.imageClick(index)} src="public/images/workout_menu_icon.png" style={{ height: '5px', margin: '0 0', "cursor": "pointer", "display": "flex" }} /> : ""}</div>
                    </Newwork>
                    <ActivityDetails>
                      <Details>
                        {data.duration > 0 ?
                          (<div style={{ "margin-right": "6.2px" }}>
                            <img src="/public/images/NewDashboardV2/timeImg.png" height={20} width={20} style={{ "width": "auto" }} />
                          </div>) : null}
                        {data.duration > 0 ?
                          (<div style={{ "color": "#9c9c9c" }}>
                            {data.duration} mins&nbsp;&nbsp;
                          </div>) : null}
                        {data.distance > 0 ?
                          (<div>
                            <img src="/public/images/NewDashboardV2/locationNew.png" height={20} width={20} style={{ "width": "auto" }} />&nbsp;&nbsp;
                          </div>) : null}
                        {data.distance > 0 ?
                        <div style={{ "color": "#9c9c9c" }}>
                          {DistanceUnit === "KILOMETER"?`${convertMilesToKm(data.distance)} km`:`${data.distance} miles`}&nbsp;&nbsp;
                        </div>: null}
                        {data.intesity ? <div className={'counterContainer'} >
                          <div className={'counterImage'}>
                            <img src="/public/images/NewDashboardV2/intensity-icon.png" height={20} style={{ "width": "auto" }}/>
                          </div>
                          {(data.intesity >= 0 && data.intesity < 2) ? <div className={'counterTitle'}>
                            {t("Rest")}
                          </div> :
                            (data.intesity >= 2 && data.intesity < 4) ? <div className={'counterTitle'}>
                              {t("Easy")} </div> : (data.intesity >= 4 && data.intesity < 6) ? <div className={'counterTitle'}>
                                {t("Moderate")} </div> : (data.intesity >= 6 && data.intesity < 8) ? <div className={'counterTitle'}>
                                  {t("Hard")} </div> : (data.intesity >= 8 && data.intesity < 10) ? <div className={'counterTitle'}>
                                    {t("Max")} </div> : null}
                        </div> : null}
                        {data.calories ? <div className={'counterContainer'} >
                          <div className={'counterImage'}>
                            <img src="/public/images/NewDashboardV2/calories.png" height={20} />
                          </div>
                          <div className={'counterTitle'}>
                            {data.calories} KCal
                          </div>
                        </div> : null}
                      </Details>
                      {data.intensity ?
                        <Details>
                          <div>
                            <img src="/public/images/NewDashboardV2/duration.png" />
                          </div>
                          <div>
                            {data.intensity}
                          </div>
                        </Details>
                        : null
                      }
                    </ActivityDetails>
                    {data.intesity && this.state.Showpopup && this.state.selectedIndex === index && <ButtonContainer margin={'1'}><button onClick={() => this.callApi(data.id)}>{t("Delete")}</button></ButtonContainer>}
                  </div>
                </Card>
              ))
              : null
            }
          </DayWorkOut>
          : null
        }
        <div>
          {this.state.showModal && <Example WorkoutId={WorkoutId} showModal={this.state.showModal} closeModal={this.closeModal} deleteWork={deleteWork} />}
        </div>
      </DisplayFlex>
    );
  }
}

WeeklyCalendar.propTypes = {
  getWorkoutHistory: PropTypes.func,
  workoutHistory: PropTypes.array,
  getMonthlyWorkoutStatus: PropTypes.array,
  monthlyActivityDateList: PropTypes.object,
  deleteWork: PropTypes.function,
  t: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  workoutHistory: state.social.workoutHistory,
  monthlyActivityDateList: state.social.monthlyActivityDateList,
  user: state.profileData.user,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkoutHistory: (date) => dispatch(getWorkoutHistory(date)),
  getMonthlyWorkoutStatus: (startDate, endDate) => dispatch(getMonthlyWorkoutStatus(startDate, endDate)),
  deleteWork: (id) => dispatch(deleteWork(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WeeklyCalendar));

